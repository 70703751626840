/**
 * Journal Recoil store
 */
import { atom } from 'recoil'

/*
* Journal Video Popup
*/
export const journalVideoUrl = atom({
  key: 'journalVideoUrl',
  default: null
})
export const journalVideoTitle = atom({
  key: 'journalVideoTitle',
  default: null
})
export const journalVideoOpen = atom({
  key: 'journalVideoOpen',
  default: false
})
